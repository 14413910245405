<template>
  <v-container v-if="permisoacceso.r =='1'">
      <v-row v-if="permisoacceso.w =='1'">
      <v-col cols="12" sm="6" md="6">
        <v-alert
          border="top"
          color="blue"
          elevation="12"
          icon="mdi-cloud-upload"
          prominent
          type="success"
        >
          <h2 class="title">{{ totalvotos.votos_estimados }}</h2>
          Votos Estimados</v-alert
        >
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-alert
          border="top"
          color="green"
          elevation="12"
          icon="mdi-cloud-upload"
          prominent
          type="success"
        >
          <h2 class="title">{{ totalvotos.votos_r }}</h2>
          Votos Efectivos</v-alert
        >
      </v-col>
    </v-row>
    <v-row v-if="permisoacceso.x =='1'">
<v-col cols="12" sm="12" md="12">
    <div id="grafico"></div>
</v-col>
    </v-row>
    <v-row v-if="permisoacceso.x =='1'">
<v-col cols="12" sm="6" md="6">
    <div id="graficoestimados"></div>
</v-col>
<v-col cols="12" sm="6" md="6">
    <div id="graficoestimadospie"></div>
</v-col>
    </v-row>
    <v-row v-show="vistaporsedes">
      <v-col
        v-for="rps in totalporsedes"
        :key="rps.sede_votacion"
        cols="12"
        sm="3"
        md="3"
      >
        <v-card elevation="24" outlined border="top" color="blue" dark>
          <v-list-item>
            <v-icon class="mr-1" x-large>mdi-domain</v-icon>
            <v-list-item-content>
              <v-list-item-title
                ><strong>{{ rps.nombre_sede }}</strong></v-list-item-title
              >
              <v-list-item-title
                ><h2>{{ rps.votos_estimados }}</h2></v-list-item-title
              >
            </v-list-item-content>
            <div align="center" justify="end">
              <v-icon class="mr-1"> mdi-comment-check </v-icon>
              <span class="subheading mr-2">{{ rps.votos_r }}</span>
              <span class="mr-1">·</span>
              <v-icon class="mr-1"> mdi-arrow-up-bold-box-outline </v-icon>
              <span class="subheading"
                >{{
                  Math.round((rps.votos_r * 100) / rps.votos_estimados)
                }}
                %</span
              >
            </div>
          </v-list-item>
          <v-card-actions>
            <v-btn
              block
              color="primary"
              elevation="2"
              small
              @click="TotalMesas(rps.sede_votacion)"
            >
              Ver Mas</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-show="vistapormesas" class="justify-center">
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" elevation="8" @click="AtrasSedes()"
          ><v-icon right small> mdi-arrow-left-bold-box </v-icon>Atras</v-btn
        >
      </v-card-actions>
    </v-row>
    <v-row v-show="vistapormesas">
      <v-col
        v-for="rpm in totalpormesas"
        :key="rpm.numero_mesa"
        cols="12"
        sm="3"
        md="3"
      >
        <v-card @click="descargar_reporte(rpm.numero_mesa)" elevation="24" outlined border="top" color="blue" dark>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>MESA {{ rpm.nombre_mesa }}</v-list-item-title>
              <v-list-item-title
                ><h2>E {{ rpm.votos_estimados }}</h2></v-list-item-title
              >
            </v-list-item-content>
            <div align="center" justify="end">
              <v-icon  class="mr-1"> mdi-comment-check </v-icon>
              <span class="subheading mr-2">R {{ rpm.votos_r }}</span>
              <span class="mr-1">·</span>
              <v-icon class="mr-1"> mdi-arrow-up-bold-box-outline </v-icon>
              <span class="subheading"
                >{{
                  Math.round((rpm.votos_r * 100) / rpm.votos_estimados)
                }}
                %</span
              >
            </div>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
    <v-container v-else fluid>
    <h1>No Tiene Permisos Para Este Modulo</h1>
  </v-container>
</template>
<script>
import ApexCharts from "apexcharts";
import axios from "axios";
export default {
  components: {},

  data() {
    return {
      totalvotos: [],
      totalporsedes: [],
      totalpormesas: [],
      vistaporsedes: true,
      vistapormesas: false,
      permisoacceso:[]
    };
  },
  created() {
     var datospermisos = JSON.parse(localStorage.getItem("data"));
    this.permisoacceso = datospermisos[6];
    this.VerGraficos();
    this.VerGraficosEstimados();
    this.VerGraficosEstimadospie();
    this.TotalVotosGeneral();
    this.TotalSedes();
  },
  methods: {
    VerGraficos() {
      axios.get("reportes/graficos").then(function (respuesta) {
          var titulo = [];
        var estimados = [];
        var efectivos = [];

        let colors = respuesta.data;
        for (const color of colors) {
          titulo.push(color[1]);
          estimados.push(parseInt(color[2]));
          efectivos.push(parseInt(color[3]));
        }
        var options = {
          series: [
            {
              name: "ESTIMADOS",
              data: estimados,
            },
            {
              name: "EFECTIVOS",
              data: efectivos,
            }
          ],
          chart: {
            type: "bar",
            height: 350,
            stacked: false,
            toolbar: {
              show: true,
            },
            zoom: {
              enabled: true,
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                  offsetX: -10,
                  offsetY: 0,
                },
              },
            },
          ],
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 10,
            },
          },
          xaxis: {
            categories: titulo,
          },
          legend: {
            position: "right",
            offsetY: 40,
          },
          fill: {
            opacity: 1,
          },
        };

        var chart = new ApexCharts(document.querySelector("#grafico"), options);
        chart.render();
      });
    },
    VerGraficosEstimados() {
      axios.get("reportes/graficos").then(function (respuesta) {
          var titulo = [];
        var estimados = [];

        let colors = respuesta.data;
        for (const color of colors) {
          titulo.push(color[1]);
          estimados.push(parseInt(color[2]));
        }
        var options = {
          series: [
            {
              name: "ESTIMADOS",
              data: estimados,
            }
          ],
          chart: {
            type: "bar",
            height: 350,
            stacked: false,
            toolbar: {
              show: true,
            },
            zoom: {
              enabled: true,
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                  offsetX: -10,
                  offsetY: 0,
                },
              },
            },
          ],
          plotOptions: {
            bar: {
              horizontal: true,
              borderRadius: 10,
            },
          },
          xaxis: {
            categories: titulo,
          },
          legend: {
            position: "right",
            offsetY: 40,
          },
          fill: {
            opacity: 1,
          },
        };

        var chart = new ApexCharts(document.querySelector("#graficoestimados"), options);
        chart.render();
      });
    },
    VerGraficosEstimadospie() {
      axios.get("reportes/graficos").then(function (respuesta) {
          var titulo = [];
        var estimados = [];

        let colors = respuesta.data;
        for (const color of colors) {
          titulo.push(color[1]);
          estimados.push(parseInt(color[2]));
        }
        var options = {
          series: estimados,
          chart: {
          width: 480,
          type: 'pie',
        },
        labels: titulo,
          responsive: [
            {
              breakpoint: 480,
               options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
            },
          ]
        };

        var chart = new ApexCharts(document.querySelector("#graficoestimadospie"), options);
        chart.render();
      });
    },
    TotalVotosGeneral: function () {
      axios.get("reportes/total").then(
        function (response) {
          this.totalvotos = response.data[0];
        }.bind(this)
      );
    },
    TotalSedes: function () {
      axios.get("reportes/sede").then(
        function (response) {
          this.totalporsedes = response.data;
        }.bind(this)
      );
    },
    TotalMesas: function (codigo) {
      axios.get(`reportes/mesas/${codigo}`).then(
        function (response) {
          this.totalpormesas = response.data;
          this.vistaporsedes = false;
          this.vistapormesas = true;
        }.bind(this)
      );
    },
    AtrasSedes: function () {
      this.vistaporsedes = true;
      this.vistapormesas = false;
    },
     descargar_reporte(codigo) {
        axios.get(`reportes/descargar/${codigo}`).then((respuesta) => {
         const datos = respuesta.data;
            var csv = datos.map(function(d){
                return JSON.stringify(Object.values(d));
            }).join('\n') 
            .replace(/(^\[)|(\]$)/mg, '');
            const url = URL.createObjectURL(new Blob([csv]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download',`${new Date().toLocaleDateString()}.csv`)
            document.body.appendChild(link)
            link.click()
          })
    },
    //  descargar_reporte(codigo) {
    //   var foo2 = "qwqw"
    //     axios.get(`reportes/descargar/${codigo}&${foo2}`).then((respuesta) => {
    //      const datos = respuesta.data;
    //         var csv = datos.map(function(d){
    //             return JSON.stringify(Object.values(d));
    //         }).join('\n') 
    //         .replace(/(^\[)|(\]$)/mg, '');
    //         const url = URL.createObjectURL(new Blob([csv]))
    //         const link = document.createElement('a')
    //         link.href = url
    //         link.setAttribute('download',`${new Date().toLocaleDateString()}.csv`)
    //         document.body.appendChild(link)
    //         link.click()
    //       })
    // },
  },
};
</script>

<style>
.v-list-item__subtitle, .v-list-item__title{
      text-overflow: initial!important;
    white-space: initial!important;
}
</style>