<template>
  <v-container v-if="permisoacceso.r =='1'" fluid>
    <v-row>
      <h3 class="font-weight-bold mb-3 titulovistas">USUARIOS</h3>
    </v-row>
    <v-divider class="lineadetitulovistas"></v-divider>
    <v-container fluid>
      <v-row v-if="permisoacceso.w =='1'" class="justify-end">
        <v-btn color="primary" @click="agregarusuario()">Agregar Usuario</v-btn>
      </v-row>
      <v-row class="justify-center">
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="cedulaonombre"
            label="Numero o Nombre"
            placeholder="Numero o Nombre"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-btn depressed color="primary" @click="buscarusuario(cedulaonombre)"
            >Buscar<v-icon right dark>mdi-magnify</v-icon></v-btn
          >
        </v-col>
      </v-row>
      <v-simple-table v-if="datousuario1 != ''">
        <template v-slot:default>
          <thead>
            <tr>
              <th>Documento</th>
              <th>Nombre</th>
              <th>Usuario</th>
              <th>Estado</th>
              <th v-if="permisoacceso.x =='1'">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="du in datousuario1" :key="du.id_admin">
              <td>{{ du.documento }}-{{ du.numero }}</td>
              <td>{{ du.nombres }} {{ du.apellidos }}</td>
              <td>{{ du.usuario }}</td>
              <td>
                <div v-if="du.estado == 'A'">
                  <v-chip class="ma-2" color="primary" label small>
                    Activo
                  </v-chip>
                </div>
                <div v-else>
                  <v-chip
                    class="ma-2"
                    color="red"
                    text-color="white"
                    label
                    small
                  >
                    Inactivo
                  </v-chip>
                </div>
              </td>
              <td v-if="permisoacceso.x =='1'">
                <v-btn
                  fab
                  rounded
                  x-small
                  dark
                  color="success"
                  @click="seleccionaruser(du)"
                >
                  <v-icon> mdi-pencil </v-icon></v-btn
                >
                <v-btn
                  fab
                  rounded
                  x-small
                  dark
                  color="primary"
                  @click="PermisosModulos(du.usuario)"
                >
                  <v-icon> mdi-key-variant </v-icon></v-btn
                >
                <v-btn
                  fab
                  rounded
                  x-small
                  dark
                  color="error"
                  @click="CambiarClave(du.id_admin)"
                >
                  <v-icon> mdi-key-variant </v-icon></v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
    <!-- ventana de diálogo registros -->
    <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="800"
    >
      <v-card>
        <v-toolbar :color="colorboton" dark>{{ titulo }}
          <v-spacer></v-spacer>
          <v-toolbar-items>
           <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container fluid>
          <v-form ref="formulariousuarios">
          <v-row>
            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="datousuario.documento"
                :items="tiposdocumentos"
                label="Tipo de Documento"
                dense
                outlined
                item-text="Nombre"
                item-value="Codigo"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                type="number" 
                v-model="datousuario.numero"
                label="Numero"
                placeholder="Numero"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="datousuario.nombres"
                label="Nombres"
                placeholder="Nombres"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="datousuario.apellidos"
                label="Apellidos"
                placeholder="Apellidos"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="datousuario.departamento"
                :items="listadodepartamentos"
                item-text="nombre_departamento"
                item-value="id_departamento"
                label="Seleccionar Departamento"
                dense
                outlined
                @change="getmunicipios(datousuario.departamento)"
                required
              ></v-select>
            </v-col>
            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="datousuario.municipio"
                :items="listadomunicipios"
                label="Seleccionar Municipio"
                dense
                outlined
                item-text="nombre_municipio"
                item-value="id_municipio"
                required
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" :md="claselargo">
              <v-text-field
                v-model="datousuario.usuario"
                label="Usuario"
                placeholder="Usuario"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col v-if="boton == 'Registrar'" cols="12" sm="6" :md="claselargo">
              <v-text-field
                v-model="datousuario.password"
                label="Contraseña"
                placeholder="Contraseña"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" elevation="8" small @click="dialog = false"
                >Cancelar</v-btn
              >
              <v-btn
                :color="colorboton"
                elevation="8"
                small
                v-on:click="
                  boton == 'Registrar'
                    ? guardarusuario(datousuario)
                    : ActualizarUsuario(datousuario)
                "
              >
                {{ boton }}
                <v-icon right small> {{ claseicono }} </v-icon></v-btn
              >
            </v-card-actions>
          </v-row>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
      <!-- ventana de diálogo permisos -->
    <v-dialog
      v-model="dialogopermisos"
      transition="dialog-top-transition"
      max-width="700"
    >
      <v-card>
        <v-toolbar color="primary" dark>Asignacion de Permisos
          <v-spacer></v-spacer>
          <v-toolbar-items>
           <v-btn
            icon
            dark
            @click="dialogopermisos = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container fluid>
         <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Nombre
          </th>
          <th class="text-left">
            Lectura
          </th>
          <th class="text-left">
            Agregar
          </th>
          <th class="text-left">
            Actualizar
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in datospermisos"
          :key="item.id_modulo"
        >
          <td>{{ item.nombre_modulo }}</td>
          <td><v-switch
          v-model="item.r"
        ></v-switch></td>
          <td><v-switch
          v-model="item.w"
        ></v-switch></td>
          <td><v-switch
          v-model="item.x"
        ></v-switch></td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  <v-row class="justify-center">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" elevation="8" small @click="dialogopermisos = false"
                >Cancelar</v-btn
              >
              <v-btn
                color="primary"
                elevation="8"
                small
                @click="GuardarPermisos()"
              >
                Asignar Permisos
                <v-icon right small> mdi-floppy </v-icon></v-btn
              >
            </v-card-actions>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
  <v-container v-else fluid>
    <h1>No Tiene Permisos Para Este Modulo</h1>
  </v-container>
  <!-- ------------------------------------------------------------------------- -->
</template>

<script>
import axios from "axios";
import tiposdocumentos from "../assets/json/tiposdocumentos.json";
export default {
  components: {},

  data() {
    return {
      titulo: "",
      boton: "",
      colorboton: "",
      claselargo: "",
      claseicono: "",
      dialog: false,
      dialogopermisos: false,
      cedulaonombre: "",
      datousuario: {
        documento: "",
        numero: "",
        nombres: "",
        apellidos: "",
        departamento: "",
        municipio: "",
        usuario: "",
        password: "",
        tiponivel: "",
      },
      listadodepartamentos: [],
      listadomunicipios: [],
      datousuario1: [],
      datospermisos: [],
      tiposdocumentos: tiposdocumentos,
      permisoacceso:[]
    };
  },

  created() {
    var datospermisos = JSON.parse(localStorage.getItem("data"));
    this.permisoacceso = datospermisos[1];
  },
  methods: {
    getdepartamentos: function () {
      axios.get("departamentos").then(
        function (response) {
          this.listadodepartamentos = response.data;
        }.bind(this)
      );
    },
    getmunicipios: function (codigo) {
      axios.get(`municipios/${codigo}`).then(
        function (response) {
          this.listadomunicipios = response.data;
        }.bind(this)
      );
    },
    buscarusuario(numerocedula) {
      if (
        numerocedula == "" ||
        numerocedula == undefined ||
        numerocedula == null
      ) {
        this.$swal.fire(
          "Intentar de nuevo",
          "Por favor Especificar un nombre o una cedula",
          "info"
        );
      } else if (
        numerocedula != "" ||
        numerocedula != undefined ||
        numerocedula != null
      ) {
        const tokenpeticion = {
          cedulaonombre: numerocedula,
        };
        axios
          .post("usuarios/buscar", tokenpeticion)
          .then((respuesta) => {
            this.datousuario1 = respuesta.data;
          })
      }
    },
    agregarusuario() {
      this.getdepartamentos();
      this.dialog = true;
      this.titulo = "Agregar Usuario";
      this.boton = "Registrar";
      this.colorboton = "primary";
      this.claselargo = "6";
      this.claseicono = "mdi-floppy";
      this.$refs.formulariousuarios.reset();
    },
    guardarusuario(datousuario) {
      if (
        datousuario.documento == "" ||
        datousuario.nombres == "" ||
        datousuario.usuario == ""
      ) {
        this.$swal.fire(
          "Intentar de nuevo",
          "Por favor Llenar Todos los campos",
          "info"
        );
      } else {
        const tokenpeticion = {
          datousuario: datousuario,
        };
        axios
          .post("usuarios/crear", tokenpeticion)
          .then((respuesta) => {
            this.dialog = false;
            this.buscarusuario(this.cedulaonombre);
            if(respuesta.data.status == true){
            this.$swal.fire({
              title:"Exito",
              text:respuesta.data.msg,
              icon:"success"})
          } else {
            this.$swal.fire({
              title:"No Registrado",
              text:respuesta.data.msg,
              icon:"error"})
          }
          })
      }
    },
    seleccionaruser(usuario) {
      this.getdepartamentos();
      this.getmunicipios(usuario.departamento);
      this.titulo = "Editar Usuario";
      this.boton = "Actualizar";
      this.colorboton = "success";
      this.claselargo = "12";
      this.claseicono = "mdi-pencil";
      this.datousuario = usuario;
      this.dialog = true;
    },
    ActualizarUsuario(datousuario) {
      const tokenpeticion = {
        editarusuario: datousuario,
      };
      axios
        .post("usuarios/actualizar", tokenpeticion)
        .then((respuesta) => {
          this.dialog = false;
          this.buscarusuario(this.cedulaonombre);
          var msg = JSON.parse(respuesta.data);
        if(msg.CODIGO == 0){
            this.$swal.fire({
              title:"Exito",
              text:msg.MENSAJE,
              icon:"success"})
              this.ListarMesas();
          } else {
            this.$swal.fire({
              title:"No Registrado",
              text:msg.MENSAJE,
              icon:"error"})
          }
        })
    },
    PermisosModulos(usuario){
      this.ListadePermisos(usuario);
      this.dialogopermisos = true;
    },
    ListadePermisos(usuario) {
      axios.get(`modulos/permisosusuario/${usuario}`)
        .then((respuesta) => {
          this.datospermisos = respuesta.data;
        })
    },
    GuardarPermisos(){
        axios.post("modulos/guardarpermisos",this.datospermisos)
        .then((respuesta) => {
          this.dialogopermisos = false;
          this.buscarusuario(this.cedulaonombre);
        if(respuesta.data.CODIGO == 0){
            this.$swal.fire({
              title:"Exito",
              text:respuesta.data.MENSAJE,
              icon:"success"})
          } else {
            this.$swal.fire({
              title:"No Registrado",
              text:respuesta.data.MENSAJE,
              icon:"error"})
          }
        })
    },
    CambiarClave(id_usuario) {
       this.$swal.fire({
  title: 'Actualizar Clave ?',
  input: 'text',
  inputAttributes: {
    autocapitalize: 'off'
  },
  showCancelButton: true,
  confirmButtonText: 'Si Actualizar',
  showLoaderOnConfirm: true,
}).then((result) => {
  if (result.isConfirmed) {
             if(result.value == ""){
              this.$swal.fire("Ups!!",
                "Por Favor Escriba la clave",
                "info"
              );
             }else {
               const datosenviar = {
                 id_usuario: id_usuario,
                 password:result.value
               };
               axios.post("usuarios/cambiarclave", datosenviar)
                 .then((respuesta) => {
                 if(respuesta.data.CODIGO == 0){
                   this.$swal.fire({
                     title:"Exito",
                     text:respuesta.data.MENSAJE,
                     icon:"success"})
                 } else {
                   this.$swal.fire({
                     title:"No Actualizado",
                     text:respuesta.data.MENSAJE,
                     icon:"error"})
                 }
                 })
             }
  }});
    },
  },
};
</script>

<style>
</style>