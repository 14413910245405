
<template>
  <!-- <v-app id="inspire">
  <v-img
   height="80%"
  width="80%"
   contain
  style="opacity: .4;position: absolute;top:40px;"
  src="../assets/images/index.svg"
></v-img>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="8">
            <v-card class="elevation-12">
              <v-window >
                <v-window-item>
                  <v-row>
                    <v-col cols="12" md="8">
                      <v-card-text class="mt-12">
                        <h1
                          class="text-center display-2 primary--text text--accent-3"
                        >Iniciar Sesion En el Sistema</h1>
                        <div class="text-center mt-4">
                          <v-btn class="mx-2" fab color="primary" outlined>
                         <v-icon size="90" style="color: #1976d2;">mdi-account-circle</v-icon>
                          </v-btn>
                        </div>
                        <h4 class="text-center mt-4">Bienvenido A su Sistema de V Dies Mil</h4>
                        <br>
                        <v-form v-on:submit.prevent="login">
                                    <v-row class="justify-center">
                                        <v-col cols="6">
                                            <v-text-field 
                                            v-model="loginEmail"  
                                            prepend-icon="mdi-account" 
                                            label="Usuario"
                                              placeholder="Usuario"
                                              dense
                                              outlined
                                              required></v-text-field>
                                        </v-col>
                                       </v-row>
                                       <v-row class="justify-center">
                                        <v-col cols="6">
                                            <v-text-field 
                                            type="password" 
                                            v-model="loginPassword" 
                                            prepend-icon="mdi-key-variant"
                                            label="Contraseña"
                                              placeholder="Contraseña"
                                              dense
                                              outlined
                                              required></v-text-field>
                                        </v-col>
                                    </v-row>
                                         <div class="text-center mt-3">
                        <v-btn x-large type="submin" color="primary"> Ingresar </v-btn>
                      </div>
                                </v-form>
                        <h3 class="text-center mt-4">Olvido Contraseña ?</h3>
                      </v-card-text>
                     
                    </v-col>
                    <v-col cols="12" md="4" class="primary accent-3">
                      <v-card-text class="white--text mt-12">
                        <h1 class="text-center display-1">Gestion de V!</h1>
                        <h5
                          class="text-center"
                        >Es una Plataforma Para que lleves un mejor Gestion</h5>
                      </v-card-text>
                      <div class="text-center">
                        <v-btn rounded outlined dark>SIGN UP</v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-window-item>
              </v-window>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      
  </v-app> -->
   <v-app>
    <div class="backgruond"></div>
    <v-main class="d-flex justify-center align-center">
      <v-col cols="10" lg="4" class="mx-auto">
        <v-card class="pa-4">
          <div class="text-center">
            <v-avatar size="100" color="indigo lighten-4">
              <v-icon size="40" color="indigo">mdi-account</v-icon>
            </v-avatar>
            <h2 class="indigo--text">Acceso al Sistema</h2>
          </div>
          <v-form v-on:submit.prevent="login" ref="form">
            <v-card-text>
              <v-text-field
                v-model="loginEmail"
                type="email"
                label="Email"
                placeholder="Email"
                prepend-inner-icon="mdi-account"
                required
              />
              <v-text-field
                      v-model="loginPassword"
                      :type="passwordShow?'text':'password'"
                      label="Password"
                      placeholder="Password"
                      prepend-inner-icon="mdi-key"
                      :append-icon="passwordShow ? 'mdi-eye':'mdi-eye-off'"
                      @click:append="passwordShow = !passwordShow"
                      required
              />
              <v-switch label="Remember me" color="indigo"></v-switch>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn  type="submit" color="indigo">
                <span class="white--text px-8">Login</span>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-main>
    <v-snackbar top color="green" v-model="snackbar">
      Login success
    </v-snackbar>
  </v-app>
</template>
<script>
import axios from "axios";
export default {
  name:"Home",
  components: {},
  data: function() {
    return {
       dialog: true,
       passwordShow:false,
       snackbar:false,
    tab: 0,
    valid: true,
    loginEmail: "",
    loginPassword: "",
    show1: false,
    token:"",
    };
  },
  methods: {
    login() {
const tokenpeticion = {
         usuario: this.loginEmail,
          password: this.loginPassword,
        };
      axios.post("login",tokenpeticion).then((respuesta) => {
          if (respuesta.data.status == true) {
            this.token = respuesta.data.token;
            sessionStorage.setItem("token", this.token);
            localStorage.setItem("nombre", respuesta.data.nombre);
            localStorage.setItem("usuario", respuesta.data.usuario);
             this.$swal.fire(
              'Bienvenido',
              'Session Iniciada Correctamente',
              'success'
            );
            var date = new Date();
                 localStorage.setItem("hora",date.setTime(date.getTime() + 3 * 60 * 60 * 1000)); // asignar horas
               // localStorage.setItem("hora",date.getTime() + 30*60000); //asignar minutos
            this.$router.push({ path: '/inicio' });
          } else {
            this.$swal.fire(
              'Ups!!',
              'Usuario o Contraseña Incorrectos',
              'error'
            )
          }
        });
    },
  },
};
</script>
<style scoped>
  .backgruond{
    background-image: url('../assets/votacion.jpg') !important;
    height: 300px;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    background-size: cover;
  }
</style>
