<template>
  <v-container v-if="permisoacceso.r =='1'" fluid>
    <v-row>
      <h3 class="font-weight-bold mb-3 titulovistas">VOTANTES</h3>
    </v-row>
    <v-divider class="lineadetitulovistas"></v-divider>
    <v-container fluid>
      <v-row v-if="permisoacceso.w =='1'" class="justify-end">
        <v-btn color="primary" @click="AbrirModalAgregar()">Agregar Votante</v-btn>
      </v-row>
      <v-row class="justify-center">
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="cedulaonombre"
            label="Numero o Nombre"
            placeholder="Numero o Nombre"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-btn depressed color="primary" @click="BuscarVotante(cedulaonombre)"
            >Buscar<v-icon right dark>mdi-magnify</v-icon></v-btn
          >
        </v-col>
      </v-row>
      <v-simple-table v-if="datosvotantes != ''">
        <template v-slot:default>
          <thead>
            <tr>
              <th>Estado V</th>
              <th>Ver Mas</th>
              <th>Documento</th>
              <th>Nombre Votante</th>
              <th>Departamento</th>
              <th>Municipio</th>
              <th>Celular</th>
              <th>Estado</th>
              <th v-if="permisoacceso.x =='1'">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="vt in datosvotantes" :key="vt.id_votante">
               <td>
                <div v-if="vt.estadodevotacion == 'V'">
                  <v-chip class="ma-2" color="success" label small>
                    V
                  </v-chip>
                </div>
                <div v-else>
                  <v-chip class="ma-2" color="red"  label small >
                    N
                  </v-chip>
                </div>
              </td>
              <td>
                <div v-if="vt.estado == 'A'">
                  <v-chip @click="VersedeVotacion(vt)" class="ma-2" color="primary" label small>
                    Ver  <v-icon size="17"> mdi-eye</v-icon>
                  </v-chip>
                </div>
              </td>
              <td>{{ vt.documento }}-{{ vt.numero }}</td>
              <td>{{ vt.pnombre }} {{ vt.snombre }} {{ vt.papellido }} {{ vt.sapellido }}</td>
              <td>{{ vt.nombre_departamento }}</td>
              <td>{{ vt.nombre_municipio }}</td>
              <td>{{ vt.celular }}</td>
              <td>
                <div v-if="vt.estado == 'A'">
                  <v-chip class="ma-2" color="primary" label small>
                    Activo
                  </v-chip>
                </div>
                <div v-else>
                  <v-chip class="ma-2" color="red"  label small >
                    Inactivo
                  </v-chip>
                </div>
              </td>
              <td v-if="permisoacceso.x =='1'">
                <v-btn
                  fab
                  rounded
                  x-small
                  dark
                  color="primary"
                  @click="AsignarMesaVotacion(vt.numero)"
                ><v-icon> mdi-clipboard-plus </v-icon></v-btn>
                <v-btn
                  fab
                  rounded
                  x-small
                  dark
                  color="cyan"
                  @click="SeleccionarVotante(vt)"
                ><v-icon> mdi-pencil </v-icon></v-btn>
                <v-btn
                  fab
                  rounded
                  x-small
                  dark
                  color="warning"
                  @click="CambiarEstadov(vt.numero)"
                ><v-icon> mdi-arrow-down-box </v-icon></v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
    <!-- ventana de diálogo registros -->
    <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="800"
    >
      <v-card>
        <v-toolbar :color="colorboton" dark>{{ titulo }}
          <v-spacer></v-spacer>
          <v-toolbar-items>
           <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container fluid>
          <v-form ref="formulariovotantes">
          <v-row>
            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="datosvotante.documento"
                :items="tiposdocumentos"
                label="Tipo de Documento"
                dense
                outlined
                item-text="Nombre"
                item-value="Codigo"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                type="number"
                v-model="datosvotante.numero"
                label="Numero"
                placeholder="Numero"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-model="datosvotante.pnombre"
                label="Primer Nombre"
                placeholder="Primer Nombre"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-model="datosvotante.snombre"
                label="Segundo Nombre"
                placeholder="Segundo Nombre"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-model="datosvotante.papellido"
                label="Primer Apellido"
                placeholder="Primer Apellido"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-model="datosvotante.sapellido"
                label="Segundo Apellido"
                placeholder="Segundo Apellido"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
             <v-col cols="12" sm="3">
              <v-menu
                v-model="menufechan"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="datosvotante.fechan"
                    label="Fecha Nacimiento"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="datosvotante.fechan"
                  @input="menufechan = false"
                  locale="es-es"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="d-flex" cols="12" sm="3">
              <v-select
                v-model="datosvotante.sexo"
                :items="listadosexo"
                item-text="Nombre"
                item-value="Codigo"
                label="Seleccionar Sexo"
                dense
                outlined
                required
              ></v-select>
            </v-col>
            <v-col class="d-flex" cols="12" sm="3">
              <v-select
                v-model="datosvotante.departamento"
                :items="listadodepartamentos"
                item-text="nombre_departamento"
                item-value="id_departamento"
                label="Seleccionar Departamento"
                dense
                outlined
                @change="getmunicipios(datosvotante.departamento)"
                required
              ></v-select>
            </v-col>
            <v-col class="d-flex" cols="12" sm="3">
              <v-select
                v-model="datosvotante.municipio"
                :items="listadomunicipios"
                label="Seleccionar Municipio"
                dense
                outlined
                item-text="nombre_municipio"
                item-value="id_municipio"
                required
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="datosvotante.barrio"
                label="Barrio"
                placeholder="Barrio"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="datosvotante.direccion"
                label="Direccion"
                placeholder="Direccion"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
              type="number"
                v-model="datosvotante.celular"
                label="Celular"
                placeholder="Celular"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
             <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="datosvotante.reclutador"
                :items="listadoreclutadores"
                label="Seleccionar Reclutador"
                dense
                outlined
                item-text="reclutador"
                item-value="numero"
                required
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" elevation="8" small @click="dialog = false"
                >Cancelar</v-btn
              >
              <v-btn
                :color="colorboton"
                elevation="8"
                small
                v-on:click="
                  boton == 'Registrar'
                    ? RegistrarVotante(datosvotante)
                    : ActualizarVotante(datosvotante)
                "
              >
                {{ boton }}
                <v-icon right small> {{ claseicono }} </v-icon></v-btn
              >
            </v-card-actions>
          </v-row>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
      <!-- ventana de diálogo Lugar de Votacion -->
    <v-dialog
      v-model="dialoglugar"
      transition="dialog-top-transition"
      max-width="500"
    >
      <v-card>
        <v-toolbar color="primary" dark>Lugar Votacion
          <v-spacer></v-spacer>
          <v-toolbar-items>
           <v-btn
            icon
            dark
            @click="dialoglugar = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container fluid>
          <v-row>
              <v-col cols="12" sm="12" md="12" class="text-md-center">
                      <v-card elevation="24">
                        <v-alert dense dark color="info">
                          <v-icon>mdi-city </v-icon
                          ><strong>{{datossedevotante.nombre_sede}}</strong
                          ><v-icon> mdi-city </v-icon>
                        </v-alert>
                        <h5 class="text-h5">Mesa</h5>
                        <h1 class="text-h1">{{datossedevotante.nombre_mesa}}</h1>
                        <v-alert dense dark color="info">
                          <strong
                            >{{datossedevotante.nombre_reclutador}}</strong
                          >
                        </v-alert>
                      </v-card>
                    </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
      <!-- ventana de diálogo Lugar de Votacion -->
    <v-dialog
      v-model="dialogAsignar"
      transition="dialog-top-transition"
      max-width="500"
    >
      <v-card>
        <v-toolbar color="primary" dark>Asignar Sede Y Mesa De Votacion
          <v-spacer></v-spacer>
          <v-toolbar-items>
           <v-btn
            icon
            dark
            @click="dialogAsignar = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container fluid>
          <v-row>
            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="sededevotacion"
                :items="listadosedes"
                item-text="nombre_sede"
                item-value="id_sede"
                label="Seleccionar Sede"
                dense
                outlined
                @change="ListarMesas(sededevotacion)"
                required
              ></v-select>
            </v-col>
            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="mesadevotacion"
                :items="listadomesas"
                label="Seleccionar Mesa"
                dense
                outlined
                item-text="nombre_mesa"
                item-value="id_mesa"
                required
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" elevation="8" small @click="dialogAsignar = false"
                >Cancelar</v-btn
              >
              <v-btn
                color="primary"
                elevation="8"
                small
                @click="GuardarMesa()"
              >
                Registrar
                <v-icon right small> mdi-floppy </v-icon></v-btn
              >
            </v-card-actions>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
  <v-container v-else fluid>
    <h1>No Tiene Permisos Para Este Modulo</h1>
  </v-container>
  <!-- ------------------------------------------------------------------------- -->
</template>

<script>
import axios from "axios";
import tiposdocumentos from "../assets/json/tiposdocumentos.json";
export default {
  components: {},

  data() {
    return {
      titulo: "",
      boton: "",
      colorboton: "",
      claselargo: "",
      claseicono: "",
      dialog: false,
      dialoglugar: false,
      dialogAsignar: false,
      cedulaonombre: "",
      menufechan:false,
      datosvotante: {
        documento:"",
        numero:"",
        pnombre:"",
        snombre:"",
        papellido:"",
        sapellido:"",
        fechan:"",
        sexo:"",
        departamento:"",
        municipio:"",
        barrio:"",
        direccion:"",
        celular:"",
        reclutador:"",
      },
      listadosexo:[{"Codigo":"M","Nombre":"Masculino"},{"Codigo":"F","Nombre":"Femenino"}],
      listadoreclutadores: [],
      listadodepartamentos: [],
      listadomunicipios: [],
      datosvotantes: [],
      datossedevotante: [],
      listadosedes: [],
      listadomesas: [],
      documentovotante:"",
      sededevotacion:"",
      mesadevotacion:"",
      tiposdocumentos: tiposdocumentos,
    };
  },

  created() {
    var datospermisos = JSON.parse(localStorage.getItem("data"));
    this.permisoacceso = datospermisos[3];
  },
  methods: {
     ListarSedes: function () {
        axios.get("mesas/listarsedes").then(
          function (response) {
            this.listadosedes = response.data;
          }.bind(this)
        );
      },
      ListarMesas: function (codigo) {
        axios.get(`mesas/listarmesas/${codigo}`).then(
          function (response) {
            this.listadomesas = response.data;
          }.bind(this)
        );
      },
    ListarReclutadores: function () {
        axios.get("votantes/listarreclutador").then(
          function (response) {
            this.listadoreclutadores = response.data;
          }.bind(this)
        );
      },
    getdepartamentos: function () {
      axios.get("departamentos").then(
        function (response) {
          this.listadodepartamentos = response.data;
        }.bind(this)
      );
    },
    getmunicipios: function (codigo) {
      axios.get(`municipios/${codigo}`).then(
        function (response) {
          this.listadomunicipios = response.data;
        }.bind(this)
      );
    },
    BuscarVotante(numerocedula) {
      if (
        numerocedula == "" ||
        numerocedula == undefined ||
        numerocedula == null
      ) {
        this.$swal.fire(
          "Intentar de nuevo",
          "Por favor Especificar un nombre o una cedula",
          "info"
        );
      } else{
        axios.post("votantes/buscar", numerocedula)
          .then((respuesta) => {
            this.datosvotantes = respuesta.data;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    AbrirModalAgregar() {
      this.ListarReclutadores();
      this.getdepartamentos();
      this.dialog = true;
      this.titulo = "Agregar Votante";
      this.boton = "Registrar";
      this.colorboton = "primary";
      this.claselargo = "4";
      this.claseicono = "mdi-floppy";
      this.$refs.formulariovotantes.reset();
    },
    RegistrarVotante(datosvotante) {
      if (
datosvotante.documento == "" || datosvotante.documento == null || datosvotante.documento == undefined ||
datosvotante.numero == "" || datosvotante.numero == null || datosvotante.numero == undefined ||
datosvotante.pnombre == "" || datosvotante.pnombre == null || datosvotante.pnombre == undefined ||
datosvotante.papellido == "" || datosvotante.papellido == null || datosvotante.papellido == undefined ||
datosvotante.fechan == "" || datosvotante.fechan == null || datosvotante.fechan == undefined ||
datosvotante.sexo == "" || datosvotante.sexo == null || datosvotante.sexo == undefined ||
datosvotante.departamento == "" || datosvotante.departamento == null || datosvotante.departamento == undefined ||
datosvotante.municipio == "" || datosvotante.municipio == null || datosvotante.municipio == undefined ||
datosvotante.barrio == "" || datosvotante.barrio == null || datosvotante.barrio == undefined ||
datosvotante.direccion == "" || datosvotante.direccion == null || datosvotante.direccion == undefined ||
datosvotante.celular == "" || datosvotante.celular == null || datosvotante.celular == undefined ||
datosvotante.reclutador == "" || datosvotante.reclutador == null || datosvotante.reclutador == undefined 
      ) {
        this.$swal.fire(
          "Intentar de nuevo",
          "Por favor Llenar Todos los campos",
          "info"
        );
      } else {
        axios.post("votantes/crear",datosvotante).then((respuesta) => {
          this.dialog = false;
          this.BuscarVotante(this.cedulaonombre);
          if(respuesta.data.status == true){
            this.$swal.fire({
              title:"Exito",
              text:respuesta.data.msg,
              icon:"success"})
          } else {
            this.$swal.fire({
              title:"No Registrado",
              text:respuesta.data.msg,
              icon:"error"})
          }
          })
      }
    },
    VersedeVotacion(datossede) {
      this.dialoglugar = true;
      this.datossedevotante = datossede;
    },
    AsignarMesaVotacion(documento) {
      this.ListarSedes();
      this.dialogAsignar = true;
      this.documentovotante = documento;
    },
     GuardarMesa() {
      var datosenviar={
        numerodocumento:this.documentovotante,
        sede:this.sededevotacion,
        mesa:this.mesadevotacion
      }
      axios.post("votantes/asignarmesa", datosenviar)
        .then((respuesta) => {
          this.dialogAsignar = false;
          this.BuscarVotante(this.cedulaonombre);
      if(respuesta.data.CODIGO == 0){
            this.$swal.fire({
              title:"Exito",
              text:respuesta.data.MENSAJE,
              icon:"success"})
              this.ListarMesas();
          } else {
            this.$swal.fire({
              title:"No Registrado",
              text:respuesta.data.MENSAJE,
              icon:"error"})
          }
        })
    },
    SeleccionarVotante(votante) {
      this.ListarReclutadores();
      this.getdepartamentos();
      this.getmunicipios(votante.departamento);
      this.titulo = "Editar Votantes";
      this.boton = "Actualizar";
      this.colorboton = "cyan";
      this.claselargo = "6";
      this.claseicono = "mdi-pencil";
      this.datosvotante = votante;
      this.dialog = true;
    },
    ActualizarVotante(datosvotante) {
      axios.post("votantes/actualizar", datosvotante)
        .then((respuesta) => {
          this.dialog = false;
          this.BuscarVotante(this.cedulaonombre);
          if(respuesta.data.CODIGO == 0){
            this.$swal.fire({
              title:"Exito",
              text:respuesta.data.MENSAJE,
              icon:"success"})
              this.ListarMesas();
          } else {
            this.$swal.fire({
              title:"No Registrado",
              text:respuesta.data.MENSAJE,
              icon:"error"})
          }
        })
    },
     CambiarEstadov(codigo) {
       this.$swal.fire({
  title: 'Cambiar Estado V',
  showCancelButton: true,
  confirmButtonText: 'Comfirmar',
  showLoaderOnConfirm: true,
  icon:"question"
}).then((result) => {
  if (result.isConfirmed) {
               axios.post("votantes/cambiarestado",codigo)
                 .then((respuesta) => {
                  this.BuscarVotante(this.cedulaonombre);
                 if(respuesta.data.CODIGO == 0){
                   this.$swal.fire({
                     title:"Exito",
                     text:respuesta.data.MENSAJE,
                     icon:"success"})
                     this.ListarMesas();
                 } else {
                   this.$swal.fire({
                     title:"No Registrado",
                     text:respuesta.data.MENSAJE,
                     icon:"error"})
                 }
                 })
  }});
    },
  },
};
</script>

<style>
</style>