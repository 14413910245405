<template>
  <v-container v-if="permisoacceso.r =='1'" fluid>
    <v-row>
      <h3 class="font-weight-bold mb-3 titulovistas">RECLUTADORES</h3>
    </v-row>
    <v-divider class="lineadetitulovistas"></v-divider>
    <v-container fluid>
      <v-row v-if="permisoacceso.w =='1'" class="justify-end">
        <v-btn color="primary" @click="AbrirModalAgregar()">Agregar Reclutador</v-btn>
      </v-row>
       <v-card class="mt-10">
    <v-tabs
    centered
      v-model="tab"
      background-color="primary"
      dark
    >
      <v-tab href="#tab-1">
        Buscar Reclutador
      </v-tab>
      <v-tab href="#tab-2">
        Reportes Reclutadores
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <v-container fluid>
<v-row class="justify-center">
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="cedulaonombre"
            label="Numero o Nombre"
            placeholder="Numero o Nombre"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-btn depressed color="primary" @click="BuscaReclutador(cedulaonombre)"
            >Buscar<v-icon right dark>mdi-magnify</v-icon></v-btn
          >
        </v-col>
      </v-row>
      <v-simple-table v-if="datosreclutadores != ''">
        <template v-slot:default>
          <thead>
            <tr>
              <th>Ver Mas</th>
              <th>Documento</th>
              <th>Nombre Reclutador</th>
              <th>Departamento</th>
              <th>Municipio</th>
              <th>Celular</th>
              <th>Estado</th>
              <th v-if="permisoacceso.x =='1'">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="dr in datosreclutadores" :key="dr.id_admin">
              <td>
                <div v-if="dr.estado == 'A'">
                  <v-chip @click="SeleccionarReclutador(dr)" class="ma-2" color="primary" label small>
                    Ver  <v-icon size="17">mdi-arrow-right-box</v-icon>
                  </v-chip>
                </div>
              </td>
              <td>{{ dr.documento }}-{{ dr.numero }}</td>
              <td>{{ dr.pnombre }} {{ dr.snombre }} {{ dr.papellido }} {{ dr.sapellido }}</td>
              <td>{{ dr.nombre_departamento }}</td>
              <td>{{ dr.nombre_municipio }}</td>
              <td>{{ dr.celular }}</td>
              <td>
                <div v-if="dr.estado == 'A'">
                  <v-chip class="ma-2" color="primary" label small>
                    Activo
                  </v-chip>
                </div>
                <div v-else>
                  <v-chip class="ma-2" color="red"  label small >
                    Inactivo
                  </v-chip>
                </div>
              </td>
              <td v-if="permisoacceso.x =='1'">
                <v-btn
                  fab
                  rounded
                  x-small
                  dark
                  color="success"
                  @click="SeleccionarReclutador(dr)"
                ><v-icon> mdi-pencil </v-icon></v-btn>
                <!-- <v-btn
                  fab
                  rounded
                  x-small
                  dark
                  color="error"
                  @click="SeleccionarReclutador(dr)"
                ><v-icon> mdi-delete </v-icon></v-btn> -->
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
        <!-- ventana de diálogo registros -->
    <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="800"
    >
      <v-card>
        <v-toolbar :color="colorboton" dark>{{ titulo }}
          <v-spacer></v-spacer>
          <v-toolbar-items>
           <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container fluid>
          <v-form ref="formularioreclutador">
          <v-row>
            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="datoreclutador.documento"
                :items="tiposdocumentos"
                label="Tipo de Documento"
                dense
                outlined
                item-text="Nombre"
                item-value="Codigo"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
              type="number"
                v-model="datoreclutador.numero"
                label="Numero"
                placeholder="Numero"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="datoreclutador.pnombre"
                label="Primer Nombre"
                placeholder="Primer Nombre"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="datoreclutador.snombre"
                label="Segundo Nombre"
                placeholder="Segundo Nombre"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="datoreclutador.papellido"
                label="Primer Apellido"
                placeholder="Primer Apellido"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="datoreclutador.sapellido"
                label="Segundo Apellido"
                placeholder="Segundo Apellido"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="datoreclutador.departamento"
                :items="listadodepartamentos"
                item-text="nombre_departamento"
                item-value="id_departamento"
                label="Seleccionar Departamento"
                dense
                outlined
                @change="getmunicipios(datoreclutador.departamento)"
                required
              ></v-select>
            </v-col>
            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="datoreclutador.municipio"
                :items="listadomunicipios"
                label="Seleccionar Municipio"
                dense
                outlined
                item-text="nombre_municipio"
                item-value="id_municipio"
                required
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
              type="number"
                v-model="datoreclutador.celular"
                label="Celular"
                placeholder="Celular"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
              <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="datoreclutador.estado"
                :items="listaestado"
                item-text="Nombre"
                item-value="Codigo"
                label="Seleccionar Estado"
                dense
                outlined
                required
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" elevation="8" small @click="dialog = false"
                >Cancelar</v-btn
              >
              <v-btn
                :color="colorboton"
                elevation="8"
                small
                v-on:click="
                  boton == 'Registrar'
                    ? RegistraReclutador(datoreclutador)
                    : ActualizaReclutador(datoreclutador)
                "
              >
                {{ boton }}
                <v-icon right small> {{ claseicono }} </v-icon></v-btn
              >
            </v-card-actions>
          </v-row>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
        </v-container>
      </v-tab-item>
      <v-tab-item value="tab-2">
        <v-container fluid>
          <v-row>
      <v-col
        v-for="rr in totalreporte"
        :key="rr.reclutador"
        cols="12"
        sm="6"
        md="6"
      >
        <v-card @click="descargar_reporte(rr.reclutador)" elevation="24" outlined border="top" color="blue" dark>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ rr.nombre_recluador }}</v-list-item-title>
              <v-list-item-title
                ><h2>{{ rr.votos_estimados }}</h2></v-list-item-title
              >
            </v-list-item-content>
            <div align="center" justify="end">
              <v-icon class="mr-1"> mdi-comment-check </v-icon>
              <span class="subheading mr-2">{{ rr.votacion }}</span>
              <span class="mr-1">·</span>
              <v-icon class="mr-1"> mdi-arrow-up-bold-box-outline </v-icon>
              <span class="subheading"
                >{{
                  Math.round((rr.votacion * 100) / rr.votos_estimados)
                }}
                %</span
              >
            </div>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
      
    </v-container>
  </v-container>
  <v-container v-else fluid>
    <h1>No Tiene Permisos Para Este Modulo</h1>
  </v-container>
  <!-- ------------------------------------------------------------------------- -->
</template>

<script>
import axios from "axios";
import tiposdocumentos from "../assets/json/tiposdocumentos.json";
export default {
  components: {},

  data() {
    return {
      tab:null,
      titulo: "",
      boton: "",
      colorboton: "",
      claselargo: "",
      claseicono: "",
      dialog: false,
      cedulaonombre: "",
      datoreclutador: {
        documento:"",
        numero:"",
        pnombre:"",
        snombre:"",
        papellido:"",
        sapellido:"",
        departamento:"",
        municipio:"",
        celular:"",
        estado:"",
      },
      listaestado:[{"Codigo":"A","Nombre":"Activo"},{"Codigo":"I","Nombre":"Inactivo"}],
      listadodepartamentos: [],
      listadomunicipios: [],
      datosreclutadores: [],
      tiposdocumentos: tiposdocumentos,
      totalreporte: [],
      permisoacceso: [],
    };
  },

  created() {
    var datospermisos = JSON.parse(localStorage.getItem("data"));
    this.permisoacceso = datospermisos[2];
    this.TotalPorReclutador();
  },
  methods: {
    getdepartamentos: function () {
      axios.get("departamentos").then(
        function (response) {
          this.listadodepartamentos = response.data;
        }.bind(this)
      );
    },
    getmunicipios: function (codigo) {
      axios.get(`municipios/${codigo}`).then(
        function (response) {
          this.listadomunicipios = response.data;
        }.bind(this)
      );
    },
    BuscaReclutador(numerocedula) {
      if (
        numerocedula == "" ||
        numerocedula == undefined ||
        numerocedula == null
      ) {
        this.$swal.fire(
          "Intentar de nuevo",
          "Por favor Especificar un nombre o una cedula",
          "info"
        );
      } else if (
        numerocedula != "" ||
        numerocedula != undefined ||
        numerocedula != null
      ) {
        const tokenpeticion = {
          cedulaonombre: numerocedula,
        };
        axios
          .post("reclutador/buscar", tokenpeticion)
          .then((respuesta) => {
            this.datosreclutadores = respuesta.data;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    AbrirModalAgregar() {
      this.getdepartamentos();
      this.dialog = true;
      this.titulo = "Agregar Reclutador";
      this.boton = "Registrar";
      this.colorboton = "primary";
      this.claselargo = "4";
      this.claseicono = "mdi-floppy";
      this.$refs.formularioreclutador.reset();
    },
    RegistraReclutador(datoreclutador) {
      if (
datoreclutador.documento == "" || datoreclutador.documento == null || datoreclutador.documento == undefined ||
datoreclutador.numero == "" || datoreclutador.numero == null || datoreclutador.numero == undefined ||
datoreclutador.pnombre == "" || datoreclutador.pnombre == null || datoreclutador.pnombre == undefined ||
datoreclutador.papellido == "" || datoreclutador.papellido == null || datoreclutador.papellido == undefined ||
datoreclutador.departamento == "" || datoreclutador.departamento == null || datoreclutador.departamento == undefined ||
datoreclutador.municipio == "" || datoreclutador.municipio == null || datoreclutador.municipio == undefined ||
datoreclutador.celular == "" || datoreclutador.celular == null || datoreclutador.celular == undefined
      ) {
        this.$swal.fire(
          "Intentar de nuevo",
          "Por favor Llenar Todos los campos",
          "info"
        );
      } else {
        axios.post("reclutador/crear",datoreclutador).then((respuesta) => {
          this.dialog = false;
          this.BuscaReclutador(this.cedulaonombre);
          if(respuesta.data.status == true){
            this.$swal.fire({
              title:"Exito",
              text:respuesta.data.msg,
              icon:"success"})
          } else {
            this.$swal.fire({
              title:"No Registrado",
              text:respuesta.data.msg,
              icon:"error"})
          }
          })
      }
    },
    SeleccionarReclutador(reclutador) {
      this.getdepartamentos();
      this.getmunicipios(reclutador.departamento);
      this.titulo = "Editar Reclutador";
      this.boton = "Actualizar";
      this.colorboton = "success";
      this.claselargo = "6";
      this.claseicono = "mdi-pencil";
      this.datoreclutador = reclutador;
      this.dialog = true;
    },
    ActualizaReclutador(datoreclutador) {
      axios.post("reclutador/actualizar", datoreclutador)
        .then((respuesta) => {
          this.dialog = false;
          this.BuscaReclutador(this.cedulaonombre);
           if(respuesta.data.CODIGO == 0){
            this.$swal.fire({
              title:"Exito",
              text:respuesta.data.MENSAJE,
              icon:"success"})
              this.ListarMesas();
          } else {
            this.$swal.fire({
              title:"No Registrado",
              text:respuesta.data.MENSAJE,
              icon:"error"})
          }
        })
    },
      TotalPorReclutador: function () {
      axios.get("reclutador/reportesporreclutador").then(
        function (response) {
          this.totalreporte = response.data;
        }.bind(this)
      );
    },
     descargar_reporte(codigo) {
        axios.get(`reclutador/descargarreporte/${codigo}`).then((respuesta) => {
         const datos = respuesta.data;
            var csv = datos.map(function(d){
                return JSON.stringify(Object.values(d));
            }).join('\n') 
            .replace(/(^\[)|(\]$)/mg, '');
            const url = URL.createObjectURL(new Blob([csv]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download',`${new Date().toLocaleDateString()}.csv`)
            document.body.appendChild(link)
            link.click()
          })
    },
  },
};
</script>

<style>
</style>