import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import moment from 'moment'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
import interceptorsSetup from './router/interceptores';
interceptorsSetup();

Vue.config.productionTip = false

let back = window.location.host == 'https://votos.grupof23.com' ? 'https://apivotos.grupof23.com' : 'https://apivotos.grupof23.com';
axios.defaults.baseURL = back

Vue.use(VueSweetalert2)

new Vue({
  router,
  store,
  vuetify,
  axios,
  VueAxios,
  moment,
  render: h => h(App)
}).$mount('#app')
