import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'

import modulos from '../views/modulos'
import inicio from '../views/inicio'
import usuarios from '../views/usuarios'
import reclutadores from '../views/reclutadores'
import votantes from '../views/votantes'
import sedes from '../views/sedes'
import mesas from '../views/mesas'
import reportes from '../views/reportes'
import nofunciona from '../views/nofunciona'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/modulos',
    component: modulos,
    children: [
        {
            name: 'inicio',
            path: '/inicio',
            component: inicio,
            meta: { requiresAuth: true }
        },
        {
            name: 'usuarios',
            path: '/usuarios',
            component: usuarios,
            meta: { requiresAuth: true }
        },
        {
            name: 'reclutadores',
            path: '/reclutadores',
            component: reclutadores,
            meta: { requiresAuth: true }
        },
        {
            name: 'votantes',
            path: '/votantes',
            component: votantes,
            meta: { requiresAuth: true }
        },
        {
            name: 'sedes',
            path: '/sedes',
            component: sedes,
            meta: { requiresAuth: true }
        },
        {
            name: 'mesas',
            path: '/mesas',
            component: mesas,
            meta: { requiresAuth: true }
        },
        {
            name: 'reportes',
            path: '/reportes',
            component: reportes,
            meta: { requiresAuth: true }
        },
    ]
},
{
  path: '*',
  component: nofunciona
}
]

const router = new VueRouter({
  mode: '',
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const token = sessionStorage.getItem('token');
    if (token) {
      next();
    } else {
      next('/');
    }
  } else {
    next();
  }
});


export default router
