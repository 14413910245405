<template>
          <v-card
    class="mx-auto"
    width="300"
  >
    <v-list>
      <v-list-item v-if="inicio.r == '1'" :to="'/inicio'">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>

        <v-list-item-title>DASHBOARD</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="usuarios.r == '1'" :to="'/usuarios'">
        <v-list-item-icon>
          <v-icon>mdi-account-edit</v-icon>
        </v-list-item-icon>

        <v-list-item-title>USUARIOS</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="reclutadores.r == '1'" :to="'/reclutadores'">
        <v-list-item-icon>
          <v-icon>mdi-account-star</v-icon>
        </v-list-item-icon>
        <v-list-item-title>RECLUTADORES</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="votantes.r == '1'"  :to="'/votantes'">
        <v-list-item-icon>
          <v-icon>mdi-account-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-title>VOTANTES</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="sedes.r == '1'" :to="'/sedes'">
        <v-list-item-icon>
          <v-icon>mdi-city</v-icon>
        </v-list-item-icon>
        <v-list-item-title>SEDES</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="mesas.r == '1'"  :to="'/mesas'">
        <v-list-item-icon>
          <v-icon>mdi-clipboard</v-icon>
        </v-list-item-icon>
        <v-list-item-title>MESAS</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="reportes.r == '1'" :to="'/reportes'">
        <v-list-item-icon>
          <v-icon>mdi-google-analytics</v-icon>
        </v-list-item-icon>
        <v-list-item-title>REPORTES</v-list-item-title>
      </v-list-item>
      <!-- ------------------------------------------------------ -->
     
    </v-list>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
data: () => ({
  inicio:[],
  usuarios:[],
  reclutadores:[],
  votantes:[],
  sedes:[],
  mesas:[],
  reportes:[],
    }),
    created() {
    this.ListarPermisos();
  },
  methods:{
    ListarPermisos() {
      var usuario = localStorage.getItem("usuario");
      axios.get(`modulos/permisos/${usuario}`)
        .then((respuesta) => {
          localStorage.setItem("data", JSON.stringify(respuesta.data));
          this.inicio = respuesta.data[0];
          this.usuarios = respuesta.data[1];
          this.reclutadores = respuesta.data[2];
          this.votantes = respuesta.data[3];
          this.sedes = respuesta.data[4];
          this.mesas = respuesta.data[5];
          this.reportes = respuesta.data[6];
        })
    },
  }
}
</script>

<style>

</style>