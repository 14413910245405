<template>
<div>
  <v-navigation-drawer
      v-model="drawer"
     :clipped="$vuetify.breakpoint.lgAndUp"
      app
      color="blue darken-3"
    >
      <v-list
        nav
        dense
      >
  <Menu/>
      </v-list>
    </v-navigation-drawer>
     <v-app-bar
     :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="blue darken-3"
      dark
    >
    <v-toolbar-title style="width: 500px" class="ml-0 pl-4">
      <v-row>
      <v-img
          style="top: 3px"
          height="75"
          max-width="199"
          src="../../assets/images/logo_nuevo.png"
        ></v-img>
      <v-app-bar-nav-icon  style="margin-top: 12px;" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>  
      </v-row>
 </v-toolbar-title>
      <v-spacer></v-spacer>

     
        <Drawer/>
        
    </v-app-bar>
 </div>
</template>

<script>
import Drawer from './Drawer.vue'
import Menu from './Menu.vue'
export default {
components:{
Drawer,
Menu
},
  data: () => ({
      drawer: true,
      group: null

    }),

 
    watch: {
      group () {
        this.drawer = false
      },
    }
}
</script>

<style>

</style>