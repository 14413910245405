<template>
  <v-container v-if="permisoacceso.r =='1'" fluid>
    <v-row>
      <h3 class="font-weight-bold mb-3 titulovistas">MESAS</h3>
    </v-row>
    <v-divider class="lineadetitulovistas"></v-divider>
    <v-container fluid>
      <v-row v-if="permisoacceso.w =='1'" class="justify-end">
        <v-btn color="primary" @click="AbrirModalAgregar()">Agregar Mesa</v-btn>
      </v-row>
      <v-card class="mt-10">
    <v-card-title>
      Total Mesas
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
        dense
      :headers="headers"
      :items="listadomesas"
      :search="search"
      multi-sort
    class="elevation-1"
    hide-default-footer
    :page.sync="page"
    @page-count="pageCount = $event"
    >
      <template v-slot:[`item.estado`]="{ item }">
     
<v-chip class="v-chip v-chip--label theme--light v-size--small  white--text"
                    :color="`${getColor(item.estado)} lighten-2`"
                    label
                    small
                  >
                    {{ item.estado}}
                  </v-chip>

    </template>
    <template v-slot:[`item.actions`]="{ item }">
    <td v-if="permisoacceso.x =='1'">
      <v-btn v-if="item.cantidad_votos_registraduria == 0"
                  fab
                  rounded
                  x-small
                  dark
                  color="primary"
                  @click="AsignarVotos(item.id_mesa)"
                ><v-icon> mdi-clipboard-plus </v-icon></v-btn>
                    <v-icon @click="SeleccionarMesa(item)" color="blue">mdi-pencil</v-icon>
                </td>
                <td v-else>
                  Sin Permiso
                </td>
    </template>
    </v-data-table>
     <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
      ></v-pagination>
    </div>
    </v-card>
    </v-container>
    <!-- ventana de diálogo registros -->
    <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="800"
    >
      <v-card>
        <v-toolbar :color="colorboton" dark>{{ titulo }}
          <v-spacer></v-spacer>
          <v-toolbar-items>
           <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container fluid>
          <v-form ref="formulariomesas">
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
              type="number"
                v-model="datosmesas.nombre_mesa"
                label="Numero Mesa"
                placeholder="Numero Mesa"
                dense
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="12" sm="6">
              <v-select
                v-model="datosmesas.sede_mesa"
                :items="listadosedes"
                item-text="nombre_sede"
                item-value="id_sede"
                label="Seleccionar Sede"
                dense
                outlined
                required
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-row>
             <v-col class="d-flex" cols="12" sm="12">
              <v-select
                v-model="datosmesas.estado"
                :items="listaestado"
                item-text="Nombre"
                item-value="Codigo"
                label="Seleccionar Estado"
                dense
                outlined
                required
              ></v-select>
            </v-col>
          </v-row>
          </v-row>
          <v-row class="justify-center">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" elevation="8" small @click="dialog = false"
                >Cancelar</v-btn
              >
              <v-btn
                :color="colorboton"
                elevation="8"
                small
                v-on:click="
                  boton == 'Registrar'
                    ? RegistrarMesa(datosmesas)
                    : ActualizarMesa(datosmesas)
                "
              >
                {{ boton }}
                <v-icon right small> {{ claseicono }} </v-icon></v-btn
              >
            </v-card-actions>
          </v-row>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
  <v-container v-else fluid>
    <h1>No Tiene Permisos Para Este Modulo</h1>
  </v-container>
  <!-- ------------------------------------------------------------------------- -->
</template>

<script>
import axios from "axios";
export default {
  components: {},

  data() {
    return {
        search: '',
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        headers: [{text: 'id_mesa',
                    align: 'center',
                    filterable: false,
                    sortable: false,
                    value: 'id_mesa',
          },
          { text: 'MESA', value: 'nombre_mesa' },
          { text: 'SEDE', value: 'nombre_sede' },
          { text: 'VOTOS E', value: 'cantidad_votos_registraduria' },
          { text: 'DEPARTAMENTO', value: 'nombre_departamento' },
          { text: 'MUNICIPIO', value: 'nombre_municipio' },
          { text: 'ESTADO', value: 'estado' },
          { text: 'ACCIONES', value: 'actions'},
        ],
        listadosedes:[],
        listadomesas:[],
        listaestado:[{"Codigo":"A","Nombre":"Activo"},{"Codigo":"I","Nombre":"Inactivo"}],
        datosmesas:{
            nombre_mesa:"",
            sede_mesa:"",
        },
      titulo: "",
      boton: "",
      colorboton: "",
      claselargo: "",
      claseicono: "",
      dialog: false,
    };
  },

  created() {
    var datospermisos = JSON.parse(localStorage.getItem("data"));
    this.permisoacceso = datospermisos[5];
    this.ListarMesas();
  },
  methods: {
      ListarMesas: function () {
        axios.get("mesas/listar").then(
          function (response) {
            this.listadomesas = response.data;
          }.bind(this)
        );
      },
      ListarSedes: function () {
        axios.get("mesas/listarsedes").then(
          function (response) {
            this.listadosedes = response.data;
          }.bind(this)
        );
      },
    getColor (estado) {
        if (estado == "I") return 'red'
        else if (estado == "A") return 'green'
        else return 'orange'
      },
    AbrirModalAgregar() {
        this.ListarSedes();
      this.dialog = true;
      this.titulo = "Agregar Sede";
      this.boton = "Registrar";
      this.colorboton = "primary";
      this.claselargo = "4";
      this.claseicono = "mdi-floppy";
      this.$refs.formulariomesas.reset();
    },
    RegistrarMesa(datosmesas) {
      if (
datosmesas.nombre_mesa == "" || datosmesas.nombre_mesa == null || datosmesas.nombre_mesa == undefined ||
datosmesas.sede_mesa == "" || datosmesas.sede_mesa == null || datosmesas.sede_mesa == undefined 
      ) {
        this.$swal.fire(
          "Intentar de nuevo",
          "Por favor Llenar Todos los campos",
          "info"
        );
      } else {
        axios.post("mesas/crear",datosmesas).then((respuesta) => {
          this.dialog = false;
          if(respuesta.data.status == true){
            this.$swal.fire({
              title:"Exito",
              text:respuesta.data.msg,
              icon:"success"})
              this.ListarMesas();
          } else {
            this.$swal.fire({
              title:"No Registrado",
              text:respuesta.data.msg,
              icon:"error"})
          }
          })
      }
    },
    SeleccionarMesa(sedes) {
      this.ListarSedes();
      this.titulo = "Editar Sede";
      this.boton = "Actualizar";
      this.colorboton = "success";
      this.claselargo = "6";
      this.claseicono = "mdi-pencil";
      this.datosmesas = sedes;
      this.dialog = true;
    },
    ActualizarMesa(datosmesas) {
      axios.post("mesas/actualizar", datosmesas)
        .then((respuesta) => {
           this.dialog = false;
          this.ListarMesas();
            if(respuesta.data.CODIGO == 0){
            this.$swal.fire({
              title:"Exito",
              text:respuesta.data.MENSAJE,
              icon:"success"})
              this.ListarMesas();
          } else {
            this.$swal.fire({
              title:"No Registrado",
              text:respuesta.data.MENSAJE,
              icon:"error"})
          }
        })
    },
      AsignarVotos(id_mesa) {
       this.$swal.fire({
  title: 'Asignar Votos A La Mesa',
  input: 'number',
  inputAttributes: {
    autocapitalize: 'off'
  },
  showCancelButton: true,
  confirmButtonText: 'Si Asignar',
  showLoaderOnConfirm: true,
}).then((result) => {
  if (result.isConfirmed) {
             if(result.value == ""){
 this.$swal.fire("Ups!!",
                "Por Favor Coloque una Cantidad",
                "info"
              );
             }else {
               const datosenviar = {
                 id_mesa: id_mesa,
                 cantidad:result.value
               };
               axios.post("mesas/asignarvotos", datosenviar)
                 .then((respuesta) => {
                 if(respuesta.data.CODIGO == 0){
                   this.$swal.fire({
                     title:"Exito",
                     text:respuesta.data.MENSAJE,
                     icon:"success"})
                     this.ListarMesas();
                 } else {
                   this.$swal.fire({
                     title:"No Registrado",
                     text:respuesta.data.MENSAJE,
                     icon:"error"})
                 }
                 })
             }
  }});
    },
  },
};
</script>

<style>
</style>